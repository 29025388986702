.landing-coupon__section-primary {
  padding: 40px 0 40px;
  text-align: center;
}

.landing-coupon__section-secondary {
  background: #fff;
  padding: 40px 0 40px;
  text-align: center;
}

.landing-coupon__minimum-requirements {
	font-size: 14px;
}

.landing-coupon__button {
	margin: 15px 0 20px 0 !important;
	padding-left: 85px !important;
	padding-right: 85px !important;
}
