#menu {
  display: inline-block;
  align-content: center;
  align-items: center;
  display: flex;
}

#nav-table {
  display: inline-block;
  margin: 0;
  margin-right: 0.5cm;
  background: transparent;
  box-shadow: none !important;
  width: auto;
}

.nav-item {
  color: white;
  text-decoration: none;
  padding: 0.8125em;
}

.nav-item:hover {
  color: white;
  text-decoration: none;
}

#sign-up {
  display: inline-block;
  background: white;
}

#log-in {
  display: inline-block;
  color: white;
  background: transparent;
  font-weight: normal;
}
