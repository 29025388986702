@import "../../../styles/variables.scss";

.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  @media (min-width: $maxMobile) {
    padding: 80px 10vw;
    form.form {
      max-width: 800px;
    }
  }
}

.success {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 80px 20vw;
  a {
    color: $colorShark;
    cursor: pointer;
  }
}
