.banner-holiday-deal-container {
  position: fixed;
  height: 40px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 102;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 7px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #f42626;
  text-decoration: none;
}

.banner-holiday-deal-container:hover {
  text-decoration: none;
}

.banner-holiday-deal-container span {
  font-family: "Circular Std";
  letter-spacing: 0.05em;
  font-size: 0.95em;
  color: white;
}

.banner-holiday-deal-container span:first-child {
  font-weight: 700;
}

.banner-holiday-deal-container span:nth-child(3) {
  font-size: 0.8em;
  opacity: 0.7;
}

@media only screen and (max-width: 767px) {
  .banner-holiday-deal-container span {
    font-size: 0.8em;
  }
  .banner-holiday-deal-container span:nth-child(3) {
    font-size: 0.6em;
  }
  .banner-holiday-deal-arrow {
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 560px) {
  .banner-holiday-deal-container span {
    font-size: 0.65em;
  }
  .banner-holiday-deal-container span:nth-child(3) {
    display: none;
  }
  .banner-holiday-deal-arrow {
    transform: scale(0.8);
  }
}
