@import "../../../styles/variables.scss";

.bootcamp-date {
  background-color: $colorShark;
  color: white;
  padding: 8px 12px;
  margin: 6px;
  font-size: 1em;
  text-align: center;
  border-radius: 7px;
  white-space: nowrap;
}