@import "../../../styles/variables.scss";
@import "../styles/animations.scss";

body {
  background-color: white !important;
}

.coupon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.loading-container {
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  background-color: #59ad77;
  svg {
    height: 20vw;
    min-height: 50px;
    max-height: 200px;
    overflow: visible !important;
    animation: svg-animation 2s linear infinite both;
    circle {
      fill: transparent;
      stroke: $primary;
      stroke-width: 6px;
      stroke-linecap: round;
      transform-origin: 50% 50%;
      stroke-dasharray: 283;
      stroke-dashoffset: 280;
      animation: circle-animation 1.4s ease-in-out infinite both;
    }
  }
}
