.start-your-free-trial__section {
	background: #fff;
	padding: 20px 0 70px;
}
.start-your-free-trial__container {
	display: flex;
	flex-direction: row;
}
.start-your-free-trial__instructions_and_perks {
	width: 45%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.start-your-free-trial__instructions_and_perks > p {
	margin-bottom: 10px;
}
.start-your-free-trial__hello {
	font-size: 22px;
	letter-spacing: -0.5px;
	color: #3c3d41;
}
.start-your-free-trial__title {
	font-size: 30px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: -0.58px;
	color: #3c3d41;
}
.start-your-free-trial__subtitle {
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: -0.58px;
	color: #3c3d41;
}
.start-your-free-trial__perks {
	margin: 40px 0;
	list-style: none;
}
.start-your-free-trial__perks > li {
	background: url("../../../../../assets/images/check-on.svg") no-repeat left center;
	padding-left: 30px;
	font-size: 19px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.74;
	letter-spacing: normal;
	color: #494949;
}
.start-your-free-trial__image {
	display: flex;
	flex-direction: column;
	width: 55%;
	justify-content: center;
	align-items: center;
}
.start-your-free-trial__image > img {
	width: 100%;
}
@media only screen and (max-width: 768px) {
	.start-your-free-trial__container {
		flex-direction: column;
	}
	.start-your-free-trial__instructions_and_perks {
		width: 100%;
		align-items: center;
	}
	.start-your-free-trial__instructions_and_perks > p {
		text-align: center;
	}
	.start-your-free-trial__image {
		width: 85%;
	}
}
