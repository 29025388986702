@import "../../../styles/variables.scss";
@import "./mixins.scss";

.second-section {
  margin-top: 200px;
  padding-top: 100px;
  display: grid;
  grid-template:
    "title previews" auto
    "description previews" auto
    / 40vw 40vw;
  grid-gap: 5vw;
  @media (max-width: $maxMobile) {
    grid-template:
      "title" auto
      "previews" 400px
      "description" auto
      / 75vw;
    grid-gap: 40px;
    @media (max-height: $maxTallMobileHeight) {
      grid-template:
        "title" auto
        "previews" 200px
        "description" auto
        / 75vw;
      grid-gap: 20px;
    }
  }
  .title {
    @include fade-inners;
    opacity: 0;
    transform: translateY(-30px);
    grid-area: title;
    font-size: 36px;
    line-height: 40px;
    font-weight: bold;
    color: $mainText;
    align-self: end;
    text-align: center;
    @media (max-width: $maxMobile) {
      font-size: 22px;
      line-height: 26px;
    }
    .highlight {
      color: $primary;
    }
  }
  .previews-container {
    @include fade-inners;
    opacity: 0;
    grid-area: previews;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      position: absolute;
    }
    .first-preview {
      height: 50%;
      z-index: 1;
      @media (max-width: $maxMobile) {
        height: 50%;
        @media (max-height: $maxTallMobileHeight) {
          height: 35%;
        }
      }
    }
    .second-preview {
      height: 40%;
      @media (max-width: $maxMobile) {
        height: 40%;
        @media (max-height: $maxTallMobileHeight) {
          height: 30%;
        }
      }
    }
  }
  .description {
    @include fade-inners;
    opacity: 0;
    transform: translateY(30px);
    grid-area: description;
    font-size: 18px;
    color: $mainText;
    text-align: center;
  }
  &.in-view {
    .title {
      opacity: 1;
      transform: translateY(0);
    }
    .previews-container {
      opacity: 1;
    }
    .description {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
