.redeem-subscription-coupon-section__section-secondary {
  background: #fff;
  padding: 40px 0 40px;
  text-align: center;
}

.redeem-subscription-coupon-section__text {
	font-family: "Circular Std";
	font-size: 17px;
	font-weight: 500;
	font-style: "normal";
	letter-spacing: "0.2px";
}

.redeem-subscription-coupon-section__button {
	margin: 15px 0 20px 0 !important;
	padding-left: 50px !important;
	padding-right: 50px !important;
}
