@import "../../../styles/variables.scss";
@import "./animations.scss";

.header-section {
  height: calc(100vh + 100px);
  min-height: 800px;
  width: 100%;
  position: relative;
  > .header-container {
    padding: 0 50px;
    height: 80px;
    background-color: $primary;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    transition: 250ms ease-in-out;
    z-index: 3;
    :first-child {
      flex-grow: 1;
    }
    @media (max-width: $maxMobile) {
      height: 64px;
      justify-content: center;
      > .logo {
        height: 44px;
        position: absolute;
        flex-grow: 0;
      }
      > .link {
        visibility: hidden;
      }
    }
    &.collapsed {
      height: 64px;
      @media (max-width: $maxMobile) {
        height: 48;
      }
      > .signup-button {
        visibility: visible;
        opacity: 1;
        cursor: pointer;
        transform: translateX(0px);
        @media (max-width: $maxMobile) {
          right: 5vw;
        }
      }
      > .logo {
        height: 44px;
        @media (max-width: $maxMobile) {
          left: 5vw;
        }
      }
    }
    > .logo {
      height: 52px;
      margin: auto;
      user-select: none;
      transition: 250ms ease-in-out;
      img {
        height: 100%;
      }
      @media (max-width: $maxMobile) {
        left: calc(50vw - 70px);
      }
    }
    > .signup-button {
      visibility: hidden;
      opacity: 0;
      font-size: 18px;
      color: $primary;
      background-color: white;
      border: 1px solid white;
      padding: 8px 16px;
      margin-right: 8px;
      border-radius: 8px;
      transition: 250ms ease-in-out;
      transform: translateX(-50px);
      &:hover {
        background-color: $primary;
        color: white;
      }
      @media (max-width: $maxMobile) {
        transform: unset;
        position: absolute;
        right: 20vw;
      }
    }
    > .link {
      color: white;
      font-size: 18px;
      margin: 0 5px;
      border-radius: 8px;
      padding: 8px;
      cursor: pointer;
      transition: 0.25s ease-in-out;
      text-decoration: none;
      &:hover {
        color: white;
        text-decoration: none;
        background-color: #ffffff50;
      }
    }
  }
  > .bg {
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $primary;
    img,
    .parallax {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  > .backdrop {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
  }
  > .content-container {
    height: calc(100% - 180px);
    margin-top: 80px;
    width: 100%;
    display: grid;
    place-items: end;
    align-items: center;
    padding: 0 10vw;
    user-select: none;
    @media (max-width: $maxMobile) {
      height: calc(100% - 164px);
      margin-top: 64px;
      padding: 0 5vw;
    }
    > .positional {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      z-index: 1;
      max-width: 600px;
      @media (max-width: $maxMobile) {
        align-items: center;
        width: 90vw;
      }
      > .title {
        font-weight: 400;
        font-size: 40px;
        line-height: 1em;
        max-width: 580px;
        color: white;
        text-align: right;
        &.amex {
          background-image: url("../../../assets/images/amex.svg");
          padding-right: 74px;
          background-repeat: no-repeat;
          background-position: 100% 50%;
          background-size: 64px;
          @media (max-width: $maxMobile) {
            width: 80vw;
            font-size: 30px;
            text-align: center;
            padding-top: 80px;
            padding-right: 0;
            background-position: 50% 0;
          }
        }
        .non-breaking {
          white-space: nowrap;
        }
        @media (max-width: $maxMobile) {
          width: 80vw;
          font-size: 30px;
          text-align: center;
          padding-top: 80px;
          padding-right: 0;
          background-position: 50% 0;
        }
        @media (max-width: $maxSmallMobile) {
          font-size: 24px;
        }
      }
      > .subtitle {
        font-size: 30px;
        padding-top: 10px;
        opacity: 0.8;
        max-width: 300px;
        font-weight: 400;
        line-height: 1em;
        text-align: right;
        color: white;
        @media (max-width: $maxMobile) {
          width: 80vw;
          font-size: 30px;
          text-align: center;
        }
        @media (max-width: $maxSmallMobile) {
          font-size: 24px;
        }
      }
      > .legal {
        color: #fff;
        font-style: italic;
        text-align: right;
        font-size: 16px;
        line-height: 20px;
        opacity: 0.76;
        max-width: 480px;
        &.amex {
          font-size: 12px;
          @media (max-width: $maxMobile) {
            width: 80vw;
            text-align: center;
            font-size: 10px;
          }
        }
        @media (max-width: $maxMobile) {
          width: 80vw;
          text-align: center;
          font-size: 14px;
        }
        > .link {
          cursor: pointer;
          color: white;
          &:hover {
            color: white;
          }
        }
      }
      > .button-container {
        margin: 20px 0 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: $maxMobile) {
          margin: 20px 0;
          align-items: center;
        }
        > .button {
          font-size: 24px;
          color: #fff;
          font-weight: 500;
          background-color: #74e09a;
          padding: 10px 24px;
          border-radius: 8px;
          cursor: pointer;
          transition: 0.25s ease-in-out;
          &:hover {
            background-color: white;
            color: $primary;
          }
          @media (max-width: $maxMobile) {
            padding: 10px 22px;
            font-size: 20px;
          }
        }
        > .subtitle {
          color: $primary;
          margin: 8px 0;
          font-size: 16px;
          @media (max-width: $maxMobile) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    .text {
      color: white;
      font-size: 18px;
      margin-bottom: 6px;
    }
    @media (max-width: $maxSmallMobile) {
      visibility: hidden;
    }
  }
  > .wave-container {
    height: 80px;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 2;
    > .wave {
      max-width: 300px;
      fill: white;
      animation: wave 7s linear infinite;
    }
  }
  > .wave-green-container {
    height: 100px;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;
    > .wave-green {
      max-width: 300px;
      fill: $primary;
      animation: wave 7s linear infinite;
    }
  }
  &.invalid {
    height: 100vh;
    > .content-container > .positional {
      > .title {
        background: none;
        padding: 0;
        max-width: 500px;
      }
      > .subtitle {
        max-width: 420px;
      }
      > .legal {
        font-size: 16px;
      }
    }
  }
}
