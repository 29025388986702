.redeem-code-help-text {
    padding-right: 25px;
    font-family: "Circular Std";
    font-size: 17px;
    font-weight: 500;
    font-style: "normal";
    letter-spacing: "0.2px";
}
  
.redeem-code-divider {
    padding-top: 25px;
    padding-bottom: 25px;
    border-style: solid;
    display: inline-block;
    border-width: thin;
    border-right-width: 0px !important;
    border-left-width: 0px !important;
    border-color: lightgrey !important;
    max-width: 100%;
}