@import "../../../styles/variables.scss";
@import "./animations.scss";
@import "./mixins.scss";

.third-section {
  margin-top: 100px;
  width: 100%;
  position: relative;
  .scene-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 25vw;
    .first-text {
      @include fade-inners;
      transition-property: opacity;
      font-size: 30px;
      line-height: 35px;
      text-align: center;
      width: 80vw;
      max-width: 850px;
      opacity: 0;
      @media (max-width: $maxMobile) {
        font-size: 24px;
      }
      &.in-view {
        opacity: 1;
      }
    }
    .second-text {
      @include fade-inners;
      transition-property: opacity;
      font-size: 45px;
      line-height: 68px;
      text-align: center;
      width: 80vw;
      max-width: 500px;
      font-weight: 600;
      opacity: 0;
      color: $mainText;
      text-align: center;
      width: 80vw;
      font-size: 36px;
      line-height: 40px;
      font-weight: 700;
      max-width: 600px;
      margin: 70px 0;
      @media (max-width: $maxMobile) {
        margin: 50px 0;
        font-size: 32px;
      }
      &.in-view {
        opacity: 1;
      }
    }
    .languages-container {
      @include fade-inners;
      opacity: 0;
      overflow: visible;
      height: auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 80%;
      max-width: 700px;
      @media (max-width: $maxMobile) {
        margin: 0 10vw;
      }
      &.in-view {
        opacity: 1;
      }
      .single-language {
        height: 90px;
        width: 90px;
        margin: 0 12px 50px 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        @media (max-width: $maxMobile) {
          height: 60px;
          width: 60px;
          margin: 30px 12px;
        }
        img {
          height: 90%;
          border: 2px solid #dcdcdc;
          border-radius: 50%;
        }
        span {
          white-space: pre-wrap;
          text-align: center;
          position: absolute;
          top: 100%;
        }
      }
      @for $i from 1 through 10 {
        .single-language:nth-child(#{$i}n) {
          animation-delay: -#{$i * 2}s;
        }
      }
    }
    .character-1,
    .character-2 {
      z-index: -2;
      font-size: 96px;
      font-weight: bolder;
      color: $primary;
      opacity: 0.25;
      position: absolute;
      @media (max-width: $maxMobile) {
        font-size: 64px;
      }
    }
    .character-1 {
      right: 5vw;
      bottom: 230px;
      @media (max-width: $maxMobile) {
        right: 10vw;
        bottom: 200px;
      }
    }
    .character-2 {
      left: 5vw;
      top: 230px;
      @media (max-width: $maxMobile) {
        left: 10vw;
        top: 200px;
      }
    }
  }

  .waves-container {
    position: absolute;
    width: 100%;
    height: 200px;
    top: 0;
    opacity: 0.5;
    transform: skewY(5deg);
    .wave-1,
    .wave-2 {
      position: absolute;
      width: 100%;
      path {
        fill: transparent;
        stroke-width: 0.1px;
        stroke: $primary;
        animation: thin-wave 7s linear infinite;
        @media (max-width: $maxMobile) {
          stroke-width: 0.2px;
        }
      }
    }
    .wave-1 {
      left: 0;
    }
    .wave-2 {
      transform: scaleX(-1) scaleY(-1);
      right: 0;
      path {
        animation: thin-wave 6s linear infinite;
      }
    }
  }
}
