.cancellation-policy {
  font-size: 0.8em;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: "#3c3d41";
  padding-top: 10px;
  padding-bottom: 25px;
}

.cancellation-policy a {
  color: black;
}

.coupon-code-redemption-help {
  font-size: 1.1em;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: black;
  padding-bottom: 17px;
  padding-top: 15px;
}

.coupon-code-redemption-help a {
  color: black;
  text-decoration: none
}
