@import "../../../styles/variables.scss";

.footer-section {
  width: 100%;
  background-color: #4f4f4f;
  display: flex;
  flex-direction: column;
  padding: 50px;
  @media (max-width: $maxMobile) {
    padding: 20px;
  }
  .sections {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;

    > div {
      margin: 0 20px 20px;
      @media (max-width: 1380px) {
        margin: 0 10px 20px 0;
      }
    }
    @media (max-width: 1380px) {
      justify-content: space-around;
    }
    @media (max-width: $maxMobile) {
      justify-content: flex-start;
      margin: 0px;
      margin-top: 40px;
    }
    .link-section {
      @media (max-width: $maxMobile) {
        width: 50%;
        margin-right: 0;
        margin-bottom: 40px;
      }
      .title {
        font-size: 18px;
        font-weight: 500;
        color: white;
      }
      .links {
        display: flex;
        flex-direction: column;
        margin-top: 14px;
        .single-link {
          font-size: 14px;
          color: white;
          opacity: 0.6;
          text-decoration: none;
          padding: 7px 0;
          transition: 250ms ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .contact-section {
      @media (max-width: $maxMobile) {
        margin: 0;
        padding-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top: 1px solid #8c8c8c;
      }
      .newsletter-container {
        .title {
          font-size: 20px;
          font-weight: 500;
          color: white;
        }
        .input-container {
          margin-top: 15px;
          display: flex;

          input {
            min-width: 260px;
            font-size: 16px;
            color: $mainText;
            padding: 5px 10px;
            background-color: white;
            border: 1px solid #c4c4c4;
            border-radius: 6px;
            @media (max-width: $maxMobile) {
              min-width: 0px;
              flex-grow: 1;
            }
            &::placeholder {
              color: #c4c4c4;
            }
          }
          .button {
            margin-left: 2px;
            display: flex;
            padding: 8px 10px;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            color: white;
            background-color: $primary;
            border-radius: 6px;
            cursor: pointer;
            transition: 250ms ease-in-out;
            &:hover {
              background-color: white;
              color: $primary;
            }
          }
        }
      }
      .social-container {
        margin-top: 20px;

        .title {
          font-size: 20px;
          font-weight: 500;
          color: white;
          @media (max-width: $maxMobile) {
            visibility: hidden;
          }
        }
        .social-links {
          margin-top: 10px;
          @media (max-width: $maxMobile) {
            display: flex;
            justify-content: center;
          }
          .link {
            margin-right: 10px;
          }
          img {
            max-width: 40px;
          }
        }
      }
    }
  }
  .bottom {
    border-top: 1px solid #8c8c8c;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    @media (max-width: $maxMobile) {
      flex-direction: column;
      justify-content: center;
    }
    .love {
      font-size: 12px;
      font-weight: 300;

      color: #d8d8d8;
      @media (max-width: $maxMobile) {
        margin-bottom: 15px;
        text-align: center;
      }
    }
    .copyright {
      font-size: 12px;
      font-weight: 300;
      color: #d8d8d8;
      @media (max-width: $maxMobile) {
        text-align: center;
      }
    }
    @media (max-width: $maxMobile) {
      border: none;
    }
  }
}
