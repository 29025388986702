.plans {
  padding-top: 40px;
  padding-bottom: 20px;
  content: "";
  overflow: hidden; /* ensure heigh = floated children*/
  clear: both;
}

.plans__container {
  display: flex;
  justify-content: space-around;
  max-width: 1119px;
  margin-left: auto;
  margin-right: auto;
}

.plan_column {
  float: left;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
  width: 25%;
}

@media only screen and (max-width: 1200px) {
  .plan_column {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .plan_column {
    width: 100%;
  }
}

.plans__plan-segment {
  width: 268px;
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}

.plans__best-value {
  padding-top: 10px;
  position: absolute;
  top: -30px;
  left: 58px;
  width: 150px;
  height: 40px;
  border-radius: 4px;
}

.plans__title-text {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.plans__monthly-price-header {
  margin-bottom: 3px !important;
}

.warn {
  color: red !important;
}

.strike-through {
  text-decoration: line-through;
  text-decoration-color: lightgrey !important;
}

.plans__text-discount-applied {
  color: lightgrey !important;
  font-weight: normal !important;
}

.plans__due-header {
  margin-top: 0px !important;
}

.plans__info-text {
  font-size: 14px;
  font-weight: bold;
  color: #72da9a;
  padding: 14px 0;
  white-space: pre-wrap;  
  border-radius: 3px;
  border: solid 1px #74e09a;
}
.plans__info-text-empty {  
  display: none;
  font-size: 14px;
  font-weight: bold;
  color: #72da9a;
  height: 66px;
  white-space: pre-wrap;
  padding-top: 13px;
  border-radius: 3px;
  border: solid 1px transparent;
}

.plans__regular-updates-text,
.plans__free-app-text {
  text-align: left !important;
  padding-left: 36px;
}

.plans__regular-updates-text::before,
.plans__free-app-text::before {
  color: #73da9a;
  content: "\2714";
  left: 27px;
  position: absolute;
  font-size: 18px;
}

.plans__plan-segment > .ui.divider {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.plans__plan-segment > * {
  text-align: center;
}

.plans__button {
  padding-left: 2em !important;
  padding-right: 2em !important;
  width: 210px !important;
  position: relative;
  top: 38px;
  left: 2px;
  margin-top: -20px !important;
  margin-left: 16px !important;
}

.plans__button__hidden {
  visibility: hidden;
}

.plans__gift-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 64px 0;
}

.plans_cancellation-policy {
  font-size: 0.8em;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  text-align: center;
}
