@import url("https://fonts.googleapis.com/css2?family=Lato&family=Sen&family=Titillium+Web&family=Ubuntu:wght@400;500;700&display=swap");
@import "../../../styles/variables.scss";

div .version-four-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  > .header-container {
    background-color: #329964;
    display: grid;
    place-items: center;
    padding: 14px;
    > .logo {
      height: 45px;
      @media (min-width: $maxMobile) {
        height: 60px;
      }
      > img {
        height: 100%;
      }
    }
  }
  > .first-section {
    align-self: stretch;
    > .title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      padding: 32px 10vw;
      background-color: #54ba7a;
      text-align: center;
      color: white;
      > .logo-container {
        margin-bottom: 32px;
        display: grid;
        place-items: center;
        > img {
          min-height: 64px;
          min-width: 64px;
          max-height: 124px;
          max-width: 124px;
        }
      }
      > h1 {
        font-family: "Ubuntu", sans-serif;
        margin: 0 2vw 32px;
        font-size: 2.2em;
        letter-spacing: 0.04em;
      }
      > p {
        font-family: "Sen", sans-serif;
        line-height: 1.25em;
        font-size: 1.2em;
        margin: 0px 2px;
      }
    }
    > svg {
      height: 70px;
      width: 100%;
      > polygon {
        fill: #54ba7a;
      }
    }
  }
  > .second-section {
    margin-top: 20px;
    padding: 64px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > h2 {
      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.8em;
      line-height: 1.3em;
      text-align: center;
      padding: 42px 24px 12px;
    }
  }
  > .third-section {
    padding: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    > h2 {
      padding: 0 24px;
      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 2em;
      line-height: 1.2em;
      text-align: center;
      color: #000000;
    }
    > svg {
      width: 90px;
      height: 12px;
      > line {
        stroke: #74e09a;
        stroke-width: 8px;
        stroke-linecap: round;
      }
    }
    > p {
      padding: 32px 34px;
      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 1.4em;
      line-height: 1.2em;
      text-align: center;
      color: #2e2e2e;
    }
    > .languages {
      margin-top: 18px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      > .single-language {
        margin: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 48px;
        > img {
          max-width: 100%;
        }
        > span {
          margin-top: 8px;
          text-align: center;
          font-size: 0.8em;
        }
      }
    }
    > .backdrop {
      position: absolute;
      width: 100%;
      height: 75%;
      @media (min-width: $maxMobile) {
        height: 100%;
      }
      background-color: #f0fbf3;
      z-index: -1;
      bottom: 0;
    }
  }
  > .fourth-section {
    padding: 12px 0;
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > h3 {
      font-family: "Ubuntu", sans-serif;
      padding: 0 45px;
      font-style: normal;
      font-weight: bold;
      font-size: 2em;
      line-height: 38px;
      color: #2e2e2e;
    }
    > ol {
      margin: 32px 0 60px;
      padding-inline-start: 0;
      > li {
        font-family: "Ubuntu", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 1.3em;
        line-height: 29px;
        color: #2e2e2e;
      }
    }
    > span {
      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      font-size: 1.2em;
      line-height: 1.4em;
      text-align: center;
      color: #000000;
      &:nth-last-child(6) {
        margin-top: 64px;
      }
      &:nth-last-child(4) {
        margin-top: 38px;
      }
      &:nth-last-child(3) {
        margin-bottom: 38px;
      }
    }
    > div:nth-last-child(1) {
      margin-top: 24px;
      background-color: #4db282;
      height: 55px;
      align-self: stretch;
    }
  }
}

.get-offer {
  padding: 26px 44px;
  border-radius: 50px;
  font-size: 1.8em;
  letter-spacing: 0.1em;
  text-decoration: none;
  font-weight: 700;
  font-family: "Ubuntu", sans-serif;
  color: white;
  background-color: #f05d42;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1), 0px 20px 48px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  &:hover {
    color: white;
    text-decoration: none;
  }
}

.video-card-container {
  padding: 24px 36px;
  text-decoration: none;
  max-width: 500px;
  &:hover {
    text-decoration: none;
  }
  > section {
    padding: 12px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.226);
    > .video-preview {
      position: relative;
      display: grid;
      place-items: center;
      > svg {
        position: absolute;
        height: 38px;
        width: 38px;
        > polygon {
          fill: rgba(255, 255, 255, 0.6);
        }
        z-index: 1;
      }
      > img {
        max-width: 100%;
        height: 240px;
        filter: brightness(0.8);
        object-fit: cover;
      }
    }
    > .video-description {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 24px 12px;
      > h3 {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4em;
        line-height: 1.3em;
        text-align: center;
        color: #4dacf9;
      }
      > p {
        font-family: "Titillium Web", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 1em;
        padding: 0px 12px;
        line-height: 1.3em;
        text-align: center;
        color: black;
      }
    }
  }
}
