@import "../../../styles/variables.scss";

.error-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 80px;
  > .inner-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 640px;
    >h3, >p {
      font-size: 1.3em;
      text-align: center;
      color: $colorShark;
    }
  }
}

.hooray-container,
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 80px 20px;
}

.hooray-container {
  > .inner-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    a {
      cursor: pointer;
    }
    > :not(:first-child) {
      margin-top: 40px;
    }
    > h3 {
      font-size: 4em;
      text-align: center;
    }
    > span {
      text-align: center;
      font-size: 1.3em;
    }
    > .dates-container {
      max-width: 500px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.form-container {
  > form {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    > br {
      margin: 12px 0;
    }
    > h1 {
      align-self: center;
      text-align: center;
    }
    > input[type="text"],
    > input[type="email"],
    > textarea {
      border: 1px solid gray;
      border-radius: 5px;
      padding: 12px 10px;
      font-size: 1.2em;
      resize: vertical;
    }
    > ul,
    li {
      list-style-type: disc;
      padding-inline-start: revert;
    }
    > textarea {
      min-height: 120px;
    }
    > label {
      font-size: 1.2em;
      font-weight: bold;
    }
    > .sublabel {
      color: rgb(54, 54, 54);
    }
    > .input-label-container {
      display: flex;
      align-items: center;
      &:not(.language-selector) {
        > label {
          margin-left: 8px;
          font-size: 1.1em;
        }
      }
      > select {
        margin-left: 8px;
      }
    }
    > input[type="submit"] {
      align-self: center;
      width: max-content;
      padding: 12px 26px;
      border-radius: 200px;
      border: none;
      color: rgb(34, 34, 34);
      font-size: 1.3em;
      font-weight: bold;
      background-color: $primary;
      cursor: pointer;
      &:hover {
        filter: opacity(0.8);
      }
    }
    > h3 {
      align-self: center;
    }
  }
}
