@import "../../../styles/variables.scss";
@import "./mixins.scss";

.first-section {
  margin-top: 100px;
  width: 85vw;
  @media (max-width: $maxMobile) {
    width: 85vw;
    display: flex;
    justify-content: center;
  }
  &-position {
    height: 600px;
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $maxMobile) {
      flex-direction: column-reverse;
      justify-content: center;
    }
  }

  .video-container {
    @include fade-inners;
    border: 2px solid $primary;
    box-shadow: -10px 10px $primary;
    width: 45vw;
    height: calc(9 * 45vw / 16);
    box-sizing: content-box;
    transform: translateY(50px);
    opacity: 0;
    @media (max-width: $maxMobile) {
      width: 85vw;
      height: calc(9 * 85vw / 16);
      margin-left: 5px;
      margin-top: 100px;
    }
    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &-text {
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: $maxMobile) {
      width: 85vw;
    }
    &-title {
      @include fade-inners;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      transform: translateX(50px);
      opacity: 0;
      span {
        color: $mainText;
        font-size: 24px;
        margin-right: 16px;
      }
    }
    &-description {
      @include fade-inners;
      font-size: 18px;
      color: $mainText;
      text-align: center;
      transform: translateX(-50px);
      opacity: 0;
      @media (max-width: $maxMobile) {
        font-size: 14px;
      }
    }
  }
  &.in-view {
    .video-container {
      transform: translateY(0px);
      opacity: 1;
    }
    .first-section-text {
      &-title {
        transform: translateX(0px);
        opacity: 1;
      }
      &-description {
        transform: translateX(0px);
        opacity: 1;
      }
    }
  }
}
