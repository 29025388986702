@import "../../../styles/variables.scss";
@import "./animations.scss";

.fourth-section {
  margin-top: 200px;
  height: 600px;
  width: 100%;
  position: relative;
  background-color: #6fcf97;
  transition: 250ms ease-in-out;
  opacity: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: $maxMobile) {
    height: 300px;
  }
  .amex-logo {
    height: 64px;
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 60px 0 20px 0;
    @media (max-width: $maxMobile) {
      margin: 0px 0 20px 0;
    }
    .button {
      color: white;
      padding: 10px 20px;
      font-size: 20px;
      background-color: #f2c94c;
      font-weight: 500;
      border-radius: 11px;
      cursor: pointer;
      transition: 250ms ease-in-out;
      &:hover {
        background-color: white;
        color: $primary;
      }
      @media (max-width: $maxMobile) {
        padding: 16px 20px;
        font-size: 20px;
      }
    }
    .subtitle {
      font-style: italic;
      color: white;
      font-size: 14px;
      text-align: center;
      opacity: 0.75;
      margin-top: 20px;
      @media (max-width: $maxMobile) {
        font-size: 16px;
      }
    }
  }
  .title {
    color: $mainText;
    text-align: center;
    width: 80vw;
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
    max-width: 600px;
    @media (max-width: $maxMobile) {
      visibility: hidden;
    }
    .highlight {
      color: white;
    }
  }
  .wave {
    &-container {
      height: 80px;
      position: absolute;
      width: 100%;
      top: -86px;
      transform: translateY(7px);
      z-index: 2;
    }
    max-width: 300px;
    fill: #6fcf97;
    animation: wave 7s linear infinite;
  }
  &.in-view {
    opacity: 1;
  }
}
