/*
 Responsive video layout for videos with 16:9 aspect ratio.
 - https://stackoverflow.com/a/35153397/222900
 - https://jameshfisher.com/2017/08/30/how-do-i-make-a-full-width-iframe/
 */
 .video__video-container {
	width: 100%;
}
.video__video-container div {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%; /* The height of the item will now be 56.25% of the width. */
}
.video__video-container div > iframe {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
